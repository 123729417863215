import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyArOpBnh07ajWSZh9pEAKHeGVmtwuudcq0",
    authDomain: "unity-g-web.firebaseapp.com",
    projectId: "unity-g-web",
    storageBucket: "unity-g-web.appspot.com",
    messagingSenderId: "995357613483",
    appId: "1:995357613483:web:c07a32814e3f3d357e46ee"
};

const firebase = initializeApp(firebaseConfig);

export default firebase;