import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import firebase from '../plugins/firebase';
import { getAuth, signOut as firebaseSignOut } from 'firebase/auth';

const store = createStore({
  state: {
    user: null,
    userEmail: null,
    userUid: null,
    office: null,
    staff: null,
    // 興味関心
    interestsQuestions: null,
    interestsPrevAnswers: null,
    interestsAnswers: null,
    // IADL
    iadlQuestions: null,
    iadlPrevAnswers: null,
    iadlAnswers: null,
  },

  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setUserEmail(state, email) {
      state.userEmail = email;
    },
    setUserUid(state, uid) {
      state.userUid = uid;
    },
    setOffice(state, office) {
      state.office = office;
    },
    setStaff(state, staff) {
      state.staff = staff;
    },
    // 興味関心
    setInterestsQuestions(state, questions) {
      state.interestsQuestions = questions;
    },
    setInterestsPrevAnswers(state, prevAnswers) {
      state.interestsPrevAnswers = prevAnswers;
    },
    setInterestsAnswers(state, answers) {
      state.interestsAnswers = answers;
    },
    // IADL
    setIADLQuestions(state, questions) {
      state.iadlQuestions = questions;
    },
    setIADLPrevAnswers(state, prevAnswers) {
      state.iadlPrevAnswers = prevAnswers;
    },
    setIADLAnswers(state, answers) {
      state.iadlAnswers = answers;
    },

  },

  actions: {
    signIn({ commit }, userCredential) {
      const user = userCredential.user;
      const email = userCredential.user.email;
      const uid = userCredential.user.uid;
      console.log(`[signIn/email]: ${email} (${uid})`);
      commit('setUser', user);
      commit('setUserEmail', email);
      commit('setUserUid', uid);
    },
    setCurrentOffice({ commit }, office) {
      console.log(`[setCurrentOffice]: ${office}`);
      commit('setOffice', office);
    },
    setCurrentStaff({ commit }, staff) {
      console.log(`[setCurrentStaff]: ${staff}`);
      commit('setStaff', staff);
    },
    async signOut({ commit }) {
      try {
        const auth = getAuth(firebase);
        await firebaseSignOut(auth);
        commit('setUser', null);
        commit('setUserEmail', null);
        commit('setUserUid', null);
      } catch (error) {
        console.error(`[signOut]: ${error}`);
      }
    },
    // 興味関心
    updateInterests({ commit }, { questions, prevAnswers, answers }) {
      console.log('[store] updateInterests:');
      console.table(questions);
      commit('setInterestsQuestions', questions);
      console.table(prevAnswers);
      commit('setInterestsPrevAnswers', prevAnswers);
      console.table(answers);
      commit('setInterestsAnswers', answers);
    },
    // IADL
    updateIADLs({ commit }, { questions, prevAnswers, answers, worried }) {
      console.log('[store] updateIADLs:');
      console.table(questions);
      commit('setIADLQuestions', questions);
      console.table(prevAnswers);
      commit('setIADLPrevAnswers', prevAnswers);
      console.table(answers);
      commit('setIADLAnswers', answers);
    }
  },

  getters: {
    isAuthenticated(state) {
      return !!state.user;
      // return !!state.userEmail;
    },
    currentUser(state) {
      return state.user;
    },
    currentUserEmail(state) {
      return state.userEmail;
    },
    currentUserUid(state) {
      return state.userUid;
    },
    currentOffice(state) {
      return state.office;
    },
    currentStaff(state) {
      return state.staff;
    },
    // 興味関心
    getInterestsQuestions(state) {
      return state.interestsQuestions;
    },
    getInterestsPrevAnswers(state) {
      return state.interestsPrevAnswers;
    },
    getInterestsAnswers(state) {
      return state.interestsAnswers;
    },
    // IADL
    getIadlQuestions(state) {
      return state.iadlQuestions;
    },
    getIadlPrevAnswers(state) {
      return state.iadlPrevAnswers;
    },
    getIadlAnswers(state) {
      return state.iadlAnswers;
    },
  },

  plugins: [
    createPersistedState({
      key: 'vuex',
      paths: [
        'userEmail',
        'userUid',
        'office',
        'staff',
        // 興味関心
        'interestsQuestions',
        'interestsPrevAnswers',
        'interestsAnswers',
        // IADL
        'iadlQuestions',
        'iadlPrevAnswers',
        'iadlAnswers',
      ],
      storage: window.localStorage,
    }),
  ],
});

export default store;

