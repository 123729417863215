import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// Vuetify
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import DateFnsAdapter from '@date-io/date-fns'
import { ja } from 'date-fns/locale'
import '@mdi/font/css/materialdesignicons.css';

const vuetify = createVuetify({
    date: {
        adapter: new DateFnsAdapter({
            formats: {
                year: 'yyyy年',
                monthAndYear: 'yyyy年M月',
                normalDateWithWeekday: 'M月d日(E)',
            },
            locale: ja,
        }),
    },
    components,
    directives,
    theme: {
        themes: {
            light: {
                dark: false,
                colors: {
                    primary: '#20b2aa',
                    secondary: '#9dc814',
                },
            },
        },
    },
})

createApp(App)
    .use(router)
    .use(store)
    .use(vuetify)
    .mount('#app');