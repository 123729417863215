import axios from 'axios';
import firebase from '../plugins/firebase';
import { getAuth } from 'firebase/auth';

// _/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/
//
// （開発用）
//    - ローカル環境: http://localhost:8080/api
//    - トンネリングサーバー経由: https://rslvrcloud.tunnelto.dev/api
// （本番用）
//    - 本番環境: https://unity-g.net/api
//
// （留意事項）
//    - API の URL に関する余計な混乱を招かないよう axios.create() で baseURL を必ず設定する
//    - API リクエスト・レスポンスは console.log() ではなく DevTools の Network タブで確認する
//
// _/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/

const API_BASE_URL = 'https://unity-g.net/api';


export const fetchData = async (url) => {
    try {
        const auth = getAuth(firebase);
        const idToken = await auth.currentUser.getIdToken();
        const api = axios.create({
            baseURL: API_BASE_URL,
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        });
        const response = await api.get(url);
        // DEBUG:
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const postData = async (url, data) => {
    try {
        const auth = getAuth(firebase);
        const idToken = await auth.currentUser.getIdToken();
        const api = axios.create({
            baseURL: API_BASE_URL,
            headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': 'multipart/form-data'
            }
        });
        const response = await api.post(url, data);
        // DEBUG:
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};


export const postJson = async (url, data) => {
    try {
        const auth = getAuth(firebase);
        const idToken = await auth.currentUser.getIdToken();
        const api = axios.create({
            baseURL: API_BASE_URL,
            headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': 'application/json'
            }
        });
        const response = await api.post(url, data);
        // DEBUG:
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const putJson = async (url, data) => {
    try {
        const auth = getAuth(firebase);
        const idToken = await auth.currentUser.getIdToken();
        const api = axios.create({
            baseURL: API_BASE_URL,
            headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': 'application/json'
            }
        });
        const response = await api.put(url, data);
        // DEBUG:
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};