<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" sm="8" md="6">
                <v-img src="/unity-logo.png" max-height="120" class="max-auto"></v-img>
                <v-card>
                    <v-card-text>
                        <v-form @submit.prevent="signIn">
                            <v-row justify="center" dense>
                                <v-col cols="10">
                                    <v-text-field v-model="email" label="メールアドレス" required></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row justify="center" dense>
                                <v-col cols="10">
                                    <v-text-field v-model="password" label="パスワード" type="password"
                                        required></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row justify="center" dense>
                                <v-col cols="12" md="4">
                                    <v-btn type="submit" color="primary" prepend-icon="mdi-login" block>
                                        ログイン
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
                <v-alert v-if="errorMessage" type="error" dense text>
                    {{ errorMessage }}
                </v-alert>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import firebase from '../plugins/firebase';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { UNITY_EST_DICT, UNITY_STAFF_DICT } from '../utils/config';

const email = ref('');
const password = ref('');
const errorMessage = ref('');
const router = useRouter();
const store = useStore();

const signIn = async () => {
    const auth = getAuth(firebase);
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email.value, password.value);
        store.dispatch('signIn', userCredential);

        // 事業所 ID・スタッフを取得・保持
        const currentOffice = UNITY_EST_DICT[userCredential.user.email];
        const currentStaff = UNITY_STAFF_DICT[userCredential.user.email];
        store.dispatch('setCurrentOffice', currentOffice);
        store.dispatch('setCurrentStaff', currentStaff);

        // ログイン後のページへ遷移
        router.push('/today');
    } catch (error) {
        errorMessage.value = 'メールアドレスまたはパスワードが間違っています';
    }
};

</script>