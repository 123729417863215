<template>
    <v-app-bar color="primary" app>
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-img src="/unity-logo.png" max-height="48px" max-width="120px" contain></v-img>
        <v-spacer></v-spacer>
        <v-btn icon @click="logout">
            <v-icon>mdi-logout</v-icon>
        </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" temporary>
        <v-list>
            <v-list-item v-for="link in links" :key="link.icon" :prepend-icon="link.icon" :title="link.text" link
                @click="navigateTo(link.route)"></v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();
const drawer = ref(false);
const links = [
    { icon: 'mdi-calendar-check-outline', text: '本日の参加者', route: '/today' },
    { icon: 'mdi-note-text-outline', text: '経過記録', route: '/progress' },
    { icon: 'mdi-head-lightbulb-outline', text: '興味関心', route: '/interest' },
    { icon: 'mdi-walk', text: '生活機能（IADL）', route: '/iadl' },
    // { icon: 'mdi-database-clock-outline', text: '予定・実績', route: '/achievement' },
    // { icon: 'mdi-clipboard-edit-outline', text: 'ご利用者情報', route: '/careuser' },
];
const navigateTo = (route) => {
    router.push(route);
    drawer.value = false;
}

const logout = async () => {
    try {
        await store.dispatch('signOut');
        router.push('/login');
    } catch (error) {
        console.error(error);
    }
}
</script>