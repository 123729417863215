<template>
    <UnityNavigation />
    <!-- 本日の予定セクション -->
    <v-container fluid>
        <v-divider></v-divider>

        <!-- 利用者選択と予定追加コントロール -->
        <v-row class="my-4" dense>
            <v-col cols="3">
                <!-- 利用者選択コンボボックス -->
                <v-combobox v-model="selectedUser" :items="availableUsers" item-title="text" item-value="value"
                    :loading="isUsersLoading" prepend-icon="mdi-human-male-female" label="ご利用者" density="compact"
                    variant="underlined" clearable :disabled="isDailySchedulesExists"
                    @click:clear="clearSelectedUser"></v-combobox>
            </v-col>
            <v-col cols="2">
                <!-- 利用者追加ボタン -->
                <v-icon small class="ml-2" :disabled="isDailySchedulesExists" @click="addCUserToday">mdi-plus</v-icon>
            </v-col>
            <v-col cols="4">
                <!-- 参加者確定ボタン -->
                <v-btn class="ml-4" type="button" color="primary" prepend-icon="mdi-book-clock" variant="elevated"
                    rounded="lg" :disabled="isDailySchedulesExists" @click="confirmDailySchedules">
                    参加者確定
                </v-btn>
            </v-col>
        </v-row>

        <v-divider></v-divider>

        <!-- ローディングスピナー -->
        <div v-if="loadingSpinner" class="loading-overlay">
            <v-progress-circular indeterminate color="primary" :size="96" :width="10"></v-progress-circular>
        </div>

        <!-- 本日のご利用者一覧表示 -->
        <v-row>
            <v-col cols="9">
                <v-card>
                    <v-card-title>本日のご利用者一覧</v-card-title>
                    <v-card-text>
                        <v-table>
                            <thead>
                                <tr>
                                    <th class="text-center">利用者ID</th>
                                    <th class="text-center">利用者名</th>
                                    <th class="text-center">利用者名（カナ）</th>
                                    <th class="text-center">入浴</th>
                                    <th class="text-center">状況</th>
                                    <th class="text-center">休み</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- 各利用者の情報行 -->
                                <tr v-for="cUser in cUsersToday" :key="cUser.id">
                                    <td class="text-center">{{ cUser.c_user_id }}</td>
                                    <td class="text-center">
                                        <!-- 利用者名をクリックしてプログレスページへ遷移 -->
                                        <router-link
                                            :to="{ name: 'progress', query: { userId: cUser.c_user_id, bathingStatus: cUser.bathing_status } }">
                                            {{ cUser.c_user_name }}
                                        </router-link>
                                    </td>
                                    <td class="text-center">{{ cUser.c_user_kana }}</td>
                                    <td class="text-center">
                                        <!-- 入浴ケアが必要な場合にアイコンを表示 -->
                                        <!-- <v-icon small v-if="cUser.bathing_care">mdi-bathtub</v-icon> -->
                                        <v-icon small v-if="cUser.bathing_care"
                                            :disabled="!isDailySchedulesExists || cUser.bathing_status === 'DONE'"
                                            :color="cUser.bathing_status === 'DONE' ? 'blue' : 'grey'"
                                            @click="openBathingDialog(cUser)">
                                            mdi-bathtub
                                        </v-icon>
                                    </td>
                                    <td class="text-center">
                                        <!-- 利用者の状況を表示 -->
                                        <v-chip small :color="getStatusColor(cUser.status)">
                                            {{ getStatusText(cUser.status) }}
                                        </v-chip>
                                    </td>
                                    <td class="text-center">
                                        <!-- 予定キャンセルボタン -->
                                        <v-icon small class="ml-2" :disabled="isDailySchedulesExists"
                                            @click="openAbsentDialog(cUser)">mdi-cancel</v-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </v-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
    <v-footer color="primary" app></v-footer>

    <!-- 入浴済み確認ダイアログ -->
    <v-dialog v-model="bathingDialogOpen" max-width="300px">
        <v-card>
            <v-card-title>入浴済み確認</v-card-title>
            <v-card-text>
                {{ userToBathing?.c_user_name ?? '選択された利用者' }} さんを入浴済みに変更しますか？
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeBathingDialog">キャンセル</v-btn>
                <v-btn color="blue darken-1" text @click="confirmBathing">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- 予定削除確認ダイアログ -->
    <v-dialog v-model="absentDialogOpen" max-width="300px">
        <v-card>
            <v-card-title>休みの変更確認</v-card-title>
            <v-card-text>
                {{ userToAbsent?.c_user_name ?? '選択された利用者' }} さんの予定を休みに変更しますか？
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeAbsentDialog">キャンセル</v-btn>
                <v-btn color="blue darken-1" text @click="confirmAbsent">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- Snackbar 通知メッセージ -->
    <v-snackbar v-model="snackbar" :timeout="3000" :color="snackbarColor">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
        </template>
    </v-snackbar>

</template>

<script setup>
import UnityNavigation from './common/UnityNavigation.vue';
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { fetchData, postJson, putJson } from '../utils/api';

const store = useStore();
const userEmail = computed(() => store.getters.currentUserEmail);
const currentOffice = computed(() => store.getters.currentOffice);

// 選択された利用者を管理
const selectedUser = ref(null);
// 全ての利用者データを格納
const estUsers = ref([]);

// 入浴ダイアログの状態管理
const bathingDialogOpen = ref(false);
const userToBathing = ref(null);
// 休みダイアログの状態管理
const absentDialogOpen = ref(false);
const userToAbsent = ref(null);

// 当日日付（yyyy-MM-dd）を取得
// const today = new Date().toISOString().split('T')[0];
const today = new Date().toLocaleDateString('sv-SE', { timeZone: 'Asia/Tokyo' });
// DEBUG: 
console.log(`[today]: ${today}`);
// DEBUG: 特定日付でのテスト
// const today = '2024-08-12';

// 本日の利用者リストを管理
const cUsersToday = ref([]);
// 日次スケジュールが既に存在するかどうかのフラグ
const isDailySchedulesExists = ref(false);
// ローディング状態を管理
const loadingSpinner = ref(false);

// 利用可能な利用者のリストを計算
const availableUsers = computed(() => {
    const fileteredUsers = estUsers.value.filter(user => {
        return !cUsersToday.value.some(cUser => cUser.c_user_id === user.c_user_id);
    });
    return fileteredUsers.map(user => ({
        text: user.c_user_name,
        value: user.c_user_id,
    }));
});
console.log(`[availableUsers]: ${availableUsers}`);

// 全ての利用者データを取得
const fetchCareUsers = async (currentOffice) => {
    if (currentOffice) {
        try {
            estUsers.value = await fetchData(`/est_users/${currentOffice}`);
        } catch (error) {
            console.error(error);
        }
    } else {
        estUsers.value = [];
    }
};

// 本日の利用者データを取得
const fetchCareUsersToday = async (currentOffice, today) => {
    let result = [];
    if (currentOffice) {
        try {
            result = await fetchData(`/c_users_today/${currentOffice}/${today}`);
            // bathing_care 属性を調整
            result = result.map(item => {
                if (item.bathing_care_id) {
                    item.bathing_care = true;
                } else {
                    item.bathing_care = false;
                }
                return item;
            });
        } catch (error) {
            console.error(error);
        }
    }
    return result;
};

// 日次スケジュール（確定済み）を取得
const fetchDailySchedules = async (currentOffice, today) => {
    let result = [];
    if (currentOffice) {
        try {
            result = await fetchData(`/daily_schedules/${currentOffice}/${today}`);
        } catch (error) {
            console.error(error);
        }
    }
    return result;
};

// 選択された利用者をクリア
const clearSelectedUser = () => {
    selectedUser.value = null;
};

// 利用者の状態に応じた色を返す関数
const getStatusColor = (status) => {
    switch (status) {
        case 'UNKNOWN':
            return 'gray';
        case 'PRESENT':
            return 'blue';
        case 'ABSENT':
            return 'red';
        case 'DONE':
            return 'green';
        default:
            return 'gray';
    }
};

// 利用者の状態に応じたテキストを返す関数
const getStatusText = (status) => {
    switch (status) {
        case 'UNKNOWN':
            return '未定';
        case 'PRESENT':
            return '参加';
        case 'ABSENT':
            return '休み';
        case 'DONE':
            return '済み';
        default:
            return '未定';
    }
};

// 利用者IDから利用者情報を取得
const getEstUserById = (cUserId) => {
    return estUsers.value.find(user => user.c_user_id === cUserId);
};

// 本日の利用者リストに新しい利用者を追加
const addCUserToday = () => {
    if (selectedUser.value) {
        const estUser = getEstUserById(selectedUser.value.value);
        const newUser = {
            u_est_id: currentOffice.value,
            c_user_id: selectedUser.value.value,
            c_user_name: estUser.c_user_name,
            c_user_kana: estUser.c_name_kana,
            care_type_id: 'S001',
            care_type_name: '通所介護 I',
            bathing_care_id: '',
            status: 'UNKNOWN',
            remarks: ''
        };
        // 一覧で認識しやすいように先頭に追加
        cUsersToday.value.unshift(newUser);
        selectedUser.value = null;
    } else {
        alert('利用者を選択してください。');
    }
};

// 入浴ダイアログを開く
const openBathingDialog = (cUser) => {
    if (cUser.bathing_care && cUser.bathing_status !== 'DONE') {
        userToBathing.value = cUser;
        bathingDialogOpen.value = true;
    }
};

// 入浴ダイアログを閉じる
const closeBathingDialog = () => {
    bathingDialogOpen.value = false;
    userToBathing.value = null;
};

// 入浴済みの確定
const confirmBathing = async () => {
    try {
        // 日次予定（DailySchedules）の更新
        const schedule = {
            u_est_id: currentOffice.value,
            c_user_id: userToBathing.value.c_user_id,
            date: today,
            bathing_status: 'DONE',
            status: userToBathing.value.status,
            remarks: '',
        }
        const done = await putJson(`/daily_schedules`, JSON.stringify(schedule));
        const userIndex = cUsersToday.value.findIndex(user => user.c_user_id === userToBathing.value.c_user_id);
        if (userIndex !== -1) {
            cUsersToday.value[userIndex].bathing_status = 'DONE';
        }
        showSnackbar('success', '入浴済みに変更しました。');
    } catch (error) {
        console.error('API call failed:', error);
        alert('エラーが発生しました。詳細はコンソールを確認してください。');
    } finally {
        closeBathingDialog();
    }
};

// 休みダイアログを開く
const openAbsentDialog = (cUser) => {
    // userToDelete.value = cUser;
    userToAbsent.value = cUser;
    absentDialogOpen.value = true;
};

// 休みダイアログを閉じる
const closeAbsentDialog = () => {
    absentDialogOpen.value = false;
    // userToDelete.value = null;
    userToAbsent.value = null;
};

// 休みの確定
const confirmAbsent = () => {
    if (userToAbsent.value) {
        changeToAbsent(userToAbsent.value);
    }
    closeAbsentDialog();
};

// 利用者の状態を休み（ABSENT）に変更
const changeToAbsent = (cUser) => {
    const index = cUsersToday.value.findIndex(user => user.c_user_id === cUser.c_user_id);
    if (index !== -1) {
        cUsersToday.value[index].status = 'ABSENT';
    }
};

// Snackbar通知の状態管理
const snackbar = ref(false);
const snackbarColor = ref('success');
const snackbarText = ref('');

// Snackbar通知を表示
const showSnackbar = (color, text) => {
    snackbarColor.value = color;
    snackbarText.value = text;
    snackbar.value = true;
};

// 日次スケジュールを確定
const confirmDailySchedules = async () => {
    try {
        const scheduleData = cUsersToday.value.map(user => ({
            u_est_id: currentOffice.value,
            c_user_id: user.c_user_id,
            c_user_name: user.c_user_name,
            c_user_kana: user.c_user_kana,
            date: today,
            care_type_id: user.care_type_id,
            bathing_care: !!user.bathing_care_id,
            bathing_status: "UNDONE",
            status: user.status === 'ABSENT' ? 'ABSENT' : 'PRESENT',
            remarks: user.remarks
        }));

        const response = await postJson('/daily_schedules', scheduleData);

        if (response && response.length > 0) {
            // 成功メッセージを表示
            showSnackbar('success', '本日の予定が確定されました。');
            // 必要に応じて、確定後の処理を追加（例: データの再取得など）
            // await fetchCareUsersToday(currentOffice.value, today);
            await getSchedules();
        } else {
            // 失敗メッセージを表示
            showSnackbar('error', '本日の予定の確定に失敗しました。\nもう一度お試しください。');
        }
    } catch (error) {
        console.error('API call failed:', error);
        alert('エラーが発生しました。詳細はコンソールを確認してください。');
    }
};

// 本日のスケジュールを取得
const getSchedules = async () => {
    loadingSpinner.value = true;
    try {
        const dailySchedules = await fetchDailySchedules(currentOffice.value, today);
        if (dailySchedules.length > 0) {
            // 参加者が確定済みの場合
            cUsersToday.value = dailySchedules;
            isDailySchedulesExists.value = true;
        } else {
            // 参加者が未確定の場合
            cUsersToday.value = await fetchCareUsersToday(currentOffice.value, today);
            isDailySchedulesExists.value = false;
        }
        await fetchCareUsers(currentOffice.value);
    } catch (error) {
        console.error('Failed to fetch data:', error);
    } finally {
        loadingSpinner.value = false;
    }
};

// コンポーネントがマウントされたときに実行
onMounted(async () => {
    await getSchedules();
});

</script>

<style scoped>
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
}
</style>