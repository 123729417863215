<template>
    <UnityNavigation />
    <!-- 経過記録 -->
    <v-container fluid>
        <v-row>
            <v-col cols="4">
                <h4 class="text-h5 text-grey-darken-2 mt-2">
                    <v-icon class="mr-2" color="primary">mdi-note-text-outline</v-icon>経過記録
                </h4>
            </v-col>
        </v-row>

        <v-divider class="mx-4 my-2"></v-divider>

        <v-form class="mt-6" @submit.prevent="submitForm">
            <v-row dense>
                <v-col cols="4">
                    <v-combobox v-model="selectedUser" :items="users" item-title="text" item-value="value"
                        :loading="isUsersLoading" prepend-icon="mdi-human-male-female" label="ご利用者" density="compact"
                        variant="underlined" clearable @click:clear="clearSelectedUser"
                        @update:modelValue="handleComboboxUpdate"></v-combobox>
                </v-col>
            </v-row>

            <v-row dense>
                <v-col cols="4">
                    <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y>
                        <template v-slot:activator="{ props }">
                            <v-text-field v-model="formattedDate" label="日付" prepend-icon="mdi-calendar" readonly
                                v-bind="props" density="compact" variant="underlined"></v-text-field>
                        </template>
                        <v-date-picker v-model="datePicker" @update:modelValue="updateDate"></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>

            <v-row dense>
                <v-col cols="4">
                    <v-textarea v-model="shortGoals" label="短期目標" prepend-icon="mdi-bullseye-arrow" density="compact"
                        variant="outlined" rows="4" readonly></v-textarea>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="4">
                    <v-textarea v-model="prevIssue" label="今回の課題" prepend-icon="mdi-page-previous-outline"
                        density="compact" variant="outlined" rows="3" readonly></v-textarea>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="4">
                    <v-textarea v-model="content" label="経過記録" prepend-icon="mdi-note-text-outline" density="compact"
                        variant="outlined" rows="12"></v-textarea>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="4">
                    <v-textarea v-model="nextIssue" label="次回の課題" prepend-icon="mdi-page-next-outline" density="compact"
                        variant="outlined" rows="3"></v-textarea>
                </v-col>
            </v-row>

            <v-row dense>
                <v-col cols="4">
                    <v-file-input v-model="images" label="画像のアップロード" multiple density="compact" variant="underlined"
                        prepend-icon="mdi-camera" accept="image/*" @change="onFileChange"></v-file-input>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-for="(image, index) in previewImages" :key="index" cols="2">
                    <v-img :src="image" aspect-ratio="1" contain></v-img>
                </v-col>
            </v-row>

            <v-row dense>
                <v-col cols="4" class="text-right">
                    <v-btn type="button" color="primary" prepend-icon="mdi-update" variant="elevated" rounded="lg"
                        :disabled="!isProgressNoteExists" @click="updateProgressNote">
                        更新
                    </v-btn>
                    <v-btn class="ml-4" type="button" color="primary" prepend-icon="mdi-arrow-right-drop-circle"
                        variant="elevated" rounded="lg" :disabled="isProgressNoteExists" @click="registerProgressNote">
                        登録
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
    <v-footer color="primary" app></v-footer>
    <!-- Snackbar 通知メッセージ -->
    <v-snackbar v-model="snackbar" :timeout="3000" :color="snackbarColor">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
        </template>
    </v-snackbar>
</template>

<script setup>
import UnityNavigation from './common/UnityNavigation.vue';
import { ref, onMounted, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { fetchData, postJson, putJson } from '../utils/api';
import { AxiosError } from 'axios';

// _/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/
//
// Vue 3 Composition API での Vuex ストアの利用
// （参考）https://zenn.dev/okakyo/articles/87faf411c4766e5ccb4a
//   - store, getters を使用する時 → computed() を使用
//   - mutation, action を使用する時 → methods を使用
//
// _/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/_/

const store = useStore();
const router = useRouter();
const route = useRoute();
const selectedOffice = computed(() => store.getters.currentOffice);
const selectedStaff = computed(() => store.getters.currentStaff);

let selectedUser = ref(null);
let users = ref([]);
const isUsersLoading = ref(false);
let isProgressNoteExists = ref(false);

const shortGoals = ref('');
const prevIssue = ref('');
const content = ref('');
const nextIssue = ref('');
const images = ref([]);
const previewImages = ref([]);
const registeredId = ref(0);
const bathingStatus = ref('UNDONE');


const fetchCareUsers = async (currentOffice) => {
    if (currentOffice) {
        isUsersLoading.value = true;
        try {
            const data = await fetchData(`/est_users/${currentOffice}`);
            users = data.map((user) => ({
                text: user.c_user_name,
                value: user.c_user_id,
            }));
        } catch (error) {
            console.error(error);
        } finally {
            isUsersLoading.value = false;
        }
    } else {
        users.value = [];
    }
};

const checkUrlParams = () => {
    const userId = route.query.userId;
    bathingStatus.value = route.query.bathingStatus;
    if (userId) {
        const user = users.find(u => u.value === userId);
        if (user) {
            selectedUser.value = user;
            handleComboboxUpdate(user);
        }
    }
};

const fetchProgressNote = async () => {
    // ご利用者が選択されている場合のみ経過記録を取得する
    if (selectedUser.value && selectedUser.value.value !== void 0) {
        content.value = '';
        try {
            const data = await fetchData(`/progress_notes/${selectedUser.value.value}/${formattedDate.value}`);
            // DEBUG:
            console.log(`[progress_note]: ${data.progress_note}`);

            // 経過記録が存在する場合はフォームに表示する
            if (data.progress_note !== null
                && data.progress_note !== void 0
                && data.progress_note.replace(/\s+/g, '') !== '') {
                // 当日の経過記録が存在する場合はフォームに表示する
                content.value = data.progress_note;
                isProgressNoteExists.value = true;
                registeredId.value = data.id;
            }
            // 次回の課題が存在する場合はフォームに表示する
            if (data.next_issue !== null
                && data.next_issue !== void 0
                && data.next_issue.replace(/\s+/g, '') !== '') {
                nextIssue.value = data.next_issue;
            }
        } catch (error) {
            if (error instanceof AxiosError && error.response?.status === 404) {
                // 404エラーの場合は経過記録が存在しないのでフォームをクリアする
                console.log('該当する経過記録が存在しません');
                content.value = '';
                isProgressNoteExists.value = false;
            } else {
                console.error(error);
            }
        }
    }
};

const fetchShortGoals = async () => {
    // ご利用者が選択されている場合のみ最新の短期目標を取得する
    if (selectedUser.value && selectedUser.value.value !== void 0) {
        shortGoals.value = '';
        try {
            const data = await fetchData(`/short_goals/${selectedUser.value.value}`);
            // 短期目標の内容を表示用に整形する
            const sentences = data.map(d => {
                if (!d['short_goal']) {
                    return null;
                }
                return d['short_goal'].endsWith('。') ? d['short_goal'] : d['short_goal'] + '。';
            }).filter(d => d !== null);
            shortGoals.value = sentences.join('\n');
        } catch (error) {
            if (error instanceof AxiosError && error.response?.status === 404) {
                // 404エラーの場合は経過記録が存在しないのでフォームをクリアする
                console.log('該当する短期目標が存在しません');
                shortGoals.value = '';
            } else {
                console.error(error);
            }
        }
    }
}

const fetchIssue = async () => {
    // ご利用者が選択されている場合のみ最新の課題を取得する
    if (selectedUser.value && selectedUser.value.value !== void 0) {
        prevIssue.value = '';
        try {
            const data = await fetchData(`/issues/${selectedUser.value.value}/${formattedDate.value}`);
            prevIssue.value = data.next_issue;
        } catch (error) {
            if (error instanceof AxiosError && error.response?.status === 404) {
                // 404エラーの場合は経過記録が存在しないのでフォームをクリアする
                console.log('該当する課題が存在しません');
                prevIssue.value = '';
            } else {
                console.error(error);
            }
        }
    }
}

const handleComboboxUpdate = (value) => {
    // ご利用者選択時に3つのハンドラを実行
    fetchProgressNote();
    fetchShortGoals();
    fetchIssue();
};

onMounted(async () => {
    await fetchCareUsers(selectedOffice.value);
    // ユーザーデータのロード後にURLパラメータをチェック
    checkUrlParams();
});

const clearSelectedUser = () => {
    selectedUser.value = null;
};

const menu = ref(false);
const datePicker = ref(new Date().toLocaleDateString('sv-SE', { timeZone: 'Asia/Tokyo' }));

const formattedDate = computed(() => {
    return formatDate(datePicker.value);
});

const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const day = ('0' + d.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
};

const updateDate = (value) => {
    datePicker.value = value;
    menu.value = false;
    fetchProgressNote();
    fetchIssue();
};


const onFileChange = () => {
    previewImages.value = [];
    images.value.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            previewImages.value.push(e.target.result);
        };
        reader.readAsDataURL(file);
    });
};

const snackbar = ref(false);
const snackbarColor = ref('success');
const snackbarText = ref('');

const showSnackbar = (color, text) => {
    snackbarColor.value = color;
    snackbarText.value = text;
    snackbar.value = true;
};

const registerProgressNote = async () => {
    try {
        const imagePromises = images.value.map(file =>
            new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e) => resolve(e.target.result.split(',')[1]);
                reader.onerror = (e) => reject(e);
                reader.readAsDataURL(file);
            })
        );
        const base64Images = await Promise.all(imagePromises);

        const body = {
            create_staff: selectedStaff.value,
            c_user_id: selectedUser.value.value,
            note_date: formattedDate.value,
            // progress_note: content.value,
            progress_note: content.value.replace(/[\n\r\t]/g, '').trim(),
            next_issue: nextIssue.value,
            images: base64Images,
        };
        const data = await postJson('/progress_note', JSON.stringify(body));
        // 日次予定（DailySchedules）の更新
        const schedule = {
            u_est_id: selectedOffice.value,
            c_user_id: selectedUser.value.value,
            date: formattedDate.value,
            bathing_status: bathingStatus.value,
            status: 'DONE',
            remarks: '',
        }
        const done = await putJson(`/daily_schedules`, JSON.stringify(schedule));
        // 成功メッセージを表示
        showSnackbar('success', '経過記録の登録を完了しました。');
        // フォームをクリア
        selectedUser.value = null;
        shortGoals.value = '';
        prevIssue.value = '';
        content.value = '';
        nextIssue.value = '';
        images.value = [];
        previewImages.value = [];
        isProgressNoteExists.value = false;
    } catch (error) {
        console.error(error);
        // 失敗メッセージを表示
        showSnackbar('error', '経過記録の登録に失敗しました。\nもう一度お試しください。');
    }
};

const updateProgressNote = async () => {
    try {
        const body = {
            id: registeredId.value,
            create_staff: selectedStaff.value,
            c_user_id: selectedUser.value.value,
            note_date: formattedDate.value,
            // progress_note: content.value,
            progress_note: content.value.replace(/[\n\r\t]/g, '').trim(),
            next_issue: nextIssue.value,
            // images: base64Images,
        };
        const data = await putJson(`/progress_note/${registeredId.value}`, JSON.stringify(body));
        // 成功メッセージを表示
        showSnackbar('success', '経過記録の更新を完了しました。');
        // フォームをクリア
        selectedUser.value = null;
        shortGoals.value = '';
        prevIssue.value = '';
        content.value = '';
        nextIssue.value = '';
        images.value = [];
        previewImages.value = [];
        isProgressNoteExists.value = false;
    } catch (error) {
        console.error(error);
        // 失敗メッセージを表示
        showSnackbar('error', '経過記録の更新に失敗しました。\nもう一度お試しください。');
    }
};

// @submit.prevent ディレクティブ向けの空実装
const submitForm = () => { };

</script>