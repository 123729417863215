import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index';
import Login from '../components/Login.vue';
import TodayToDo from '../components/TodayToDo.vue';
import ProgressNotes from '../components/ProgressNotes.vue';
import Interests from '../components/InterestsCarousel.vue';
import InterestsResult from '../components/InterestsResult.vue';
import InstrumentalADL from '../components/InstrumentalADLCarousel.vue';
import InstrumentalADLResult from '../components/InstrumentalADLResult.vue';
import CareUsers from '../components/CareUsers.vue';
import Achievements from '../components/Achievements.vue';
import NotFound from '../components/NotFound.vue';
import firebase from '../plugins/firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const routes = [
    {
        path: '/',
        redirect: '/login',
    },
    {
        path: '/today',
        name: 'today',
        component: TodayToDo,
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/progress',
        name: 'progress',
        component: ProgressNotes,
    },
    {
        path: '/interest',
        name: 'interest',
        component: Interests,
    },
    {
        path: '/interest-result',
        name: 'interest-result',
        component: InterestsResult,
    },
    {
        path: '/iadl',
        name: 'iadl',
        component: InstrumentalADL,
    },
    {
        path: '/iadl-result',
        name: 'iadl-result',
        component: InstrumentalADLResult,
    },
    {
        path: '/achievement',
        name: 'achievement',
        component: Achievements,
    },
    {
        path: '/careuser',
        name: 'careuser',
        component: CareUsers,
    },
    {
        path: '/:catchAll(.*)',
        name: 'not-found',
        component: NotFound,
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

const auth = getAuth(firebase);

// 認証チェック
// ToDo: 開発中の一時無効化
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    onAuthStateChanged(auth, (user) => {
        if (to.path === '/login') {
            next();
        } else if (requiresAuth && !user) {
            next({ path: '/login', query: { redirect: to.fullPath } });
        } else {
            console.log(`[router.beforeEach] Authenticated`);
            next();
        }
    });
});

export default router;