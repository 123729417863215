<!-- src/views/NotFound.vue -->
<template>
    <v-container class="fill-height">
        <v-row justify="center">
            <v-col cols="12" sm="8" md="6" lg="4">
                <v-card class="elevation-12">
                    <v-card-title class="headline text-center">
                        404 Not Found
                    </v-card-title>
                    <v-card-text class="text-center">
                        <p>お探しのページは見つかりませんでした。</p>
                        <p>URLが正しいか確認してください。</p>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn color="primary" to="/">
                            トップページへ戻る
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>