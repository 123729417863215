<template>
    <UnityNavigation />
    <!-- 生活機能（結果表示） -->

    <v-container fluid class="d-flex flex-column" style="height: calc(100vh - 64px);">
        <v-row>
            <v-col cols="12">
                <h4 class="text-h5 text-grey-darken-2 mt-2">
                    <v-icon class="mr-2" color="primary">mdi-walk</v-icon>生活機能（IADL）
                </h4>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <h3 class="mt-2">お疲れ様でした！質問は以上です。スタッフをお呼び下さい。</h3>
            </v-col>
        </v-row>

        <v-divider class="mx-4 my-2"></v-divider>

        <v-row class="flex-grow-1 overflow-y-auto">
            <v-col cols="9">
                <v-card>
                    <!-- <v-card-title>本日のご利用者一覧</v-card-title> -->
                    <v-card-text>
                        <v-table fixed-header height="100%">
                            <thead>
                                <tr>
                                    <th class="text-center">No.</th>
                                    <th class="text-center">設問</th>
                                    <th class="text-center">今回</th>
                                    <th class="text-center">前回</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in compared" :key="index">
                                    <td class="text-center">{{ item.qIndex }}</td>
                                    <td class="text-left">{{ item.question }}</td>
                                    <td class="text-center">{{ item.answer }} / {{ item.worried }}</td>
                                    <td class="text-center">{{ item.prevAnswer }} / {{ item.prevWorried }}</td>
                                </tr>
                            </tbody>
                        </v-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>

    <v-footer color="primary" app></v-footer>
</template>

<script setup>
import UnityNavigation from './common/UnityNavigation.vue';
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();

console.log('[InstrumentalADLResult] store.state:');
const qData = computed(() => store.getters.getIadlQuestions);
console.table(qData.value);
const answers = computed(() => store.getters.getIadlAnswers);
console.table(answers.value);
const prevAnswers = computed(() => store.getters.getIadlPrevAnswers);
console.table(prevAnswers.value);

const options = ref([
    { label: '自立', value: 'self' },
    { label: '見守り', value: 'watch' },
    { label: '一部介助', value: 'partial' },
    { label: '全介助', value: 'all' }
]);
const optionsWorried = ref([
    { label: '困っている', value: 'worried' },
    { label: '困ってない', value: 'not' }
]);

const getOptionsJp = (value) => {
    const option = options.value.find(item => item.value === value);
    return option ? option.label : '';
};

const getOptionsWorriedJp = (value) => {
    const option = optionsWorried.value.find(item => item.value === value);
    return option ? option.label : '';
};

// 結果表示用データ
const compared = computed(() => {
    return qData.value.map((item, index) => ({
        qIndex: index + 1,
        question: item.question,
        answer: getOptionsJp(answers.value[index].answer),
        worried: getOptionsWorriedJp(answers.value[index].worried),
        prevAnswer: getOptionsJp(prevAnswers.value[index].answer),
        prevWorried: getOptionsWorriedJp(prevAnswers.value[index].worried),
    }));
});

</script>

<style scoped>
.v-table {
    height: 100%;
}
</style>