
export const UNITY_EST_DICT = {
    "care.g.kokubu@gmail.com": "U01",
    "care.g.kajiki@gmail.com": "U02",
    "care.g.next@gmail.com": "U03",
    "care.g.sueyoshi@gmail.com": "U04",
    "care.g.ishiki@gmail.com": "U05",
};


export const UNITY_STAFF_DICT = {
    "care.g.kokubu@gmail.com": "U0007",
    "care.g.kajiki@gmail.com": "U0008",
    "care.g.next@gmail.com": "U0009",
    "care.g.sueyoshi@gmail.com": "U0010",
    "care.g.ishiki@gmail.com": "U0011",
};

export const INTERESTS_IMAGES = [
    '01_eat.webp',
    '02_toilet.webp',
    '03_bath.webp',
    '04_clothes.webp',
    '05_grooming.webp',
    '06_going_out.webp',
    '07_cooking.webp',
    '08_laundry.webp',
    '09_sweeping.webp',
    '10_sleeping.webp',
    '11_teeth_brush.webp',
    '12_telephone.webp',
    '13_medicine.webp',
    '14_housework.webp',
    '15_sorting_out.webp',
    '16_shopping.webp',
    '17_finance.webp',
    '18_livingware.webp',
    '19_furniture.webp',
    '20_gardening.webp',
    '21_driving.webp',
    '22_public_transport.webp',
    '23_crochet.webp',
    '24_sewing.webp',
    '25_farming.webp',
    '26_friends.webp',
    '27_family.webp',
    '28_grandchild.webp',
    '29_pet.webp',
    '30_opposite.webp',
    '31_grave.webp',
    '32_lifelong.webp',
    '33_reading.webp',
    '34_haiku.webp',
    '35_calligraphy.webp',
    '36_bar.webp',
    '37_drawing.webp',
    '38_computer.webp',
    '39_picture.webp',
    '40_movie.webp',
    '41_tea_flower.webp',
    '42_singing.webp',
    '43_music.webp',
    '44_game.webp',
    '45_exercise.webp',
    '46_walking.webp',
    '47_sports.webp',
    '48_dance.webp',
    '49_watching.webp',
    '50_gambling.webp',
    '51_community.webp',
    '52_volunteer.webp',
    '53_senior_club.webp',
    '54_working.webp',
    '55_travel.webp',
];

export const IADL_IMAGES = [
    '01_meal.webp',
    '02_toilet.webp',
    '03_bath.webp',
    '04_change_clothes.webp',
    '05_straighten.webp',
    '06_transferring.webp',
    '07_indoors.webp',
    '08_outdoors.webp',
    '09_rise_fall.webp',
    '10_cooking.webp',
    '11_laundry.webp',
    '12_cleaning.webp',
    '13_getting_up.webp',
    '14_sitting.webp',
    '15_buildup.webp',
    '16_standing.webp',
    '17_urination.webp',
    '18_defecation.webp',
];