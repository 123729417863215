<template>
    <UnityNavigation />
    <!-- 興味関心 -->
    <v-container fluid>
        <v-row>
            <v-col cols="4">
                <h4 class="text-h5 text-grey-darken-2 mt-2">
                    <v-icon class="mr-2" color="primary">mdi-head-lightbulb-outline</v-icon>興味関心
                </h4>
            </v-col>
        </v-row>

        <v-divider class="mx-4 my-2"></v-divider>

        <v-form class="mt-6" @submit.prevent="submitForm">
            <v-row dense>
                <v-col cols="3" class="ml-1">
                    <v-combobox v-model="selectedUser" :items="users" item-title="text" item-value="value"
                        :loading="isUsersLoading" prepend-icon="mdi-human-male-female" label="ご利用者" density="compact"
                        variant="underlined" clearable @click:clear="clearSelectedUser"
                        @update:modelValue="fetchPreviousIdlCheck"></v-combobox>
                </v-col>
                <v-col cols="3" class="text-center">
                    <p>{{ currentSlide + 1 }} / {{ questions.length }}</p>
                </v-col>
                <v-col cols="3" class="text-left">
                    <v-btn type="submit" color="primary" prepend-icon="mdi-arrow-right-drop-circle" variant="elevated"
                        rounded="lg">
                        登録
                    </v-btn>
                </v-col>
            </v-row>

            <v-divider class="mx-4 my-2"></v-divider>

            <v-carousel height="700px" v-model="currentSlide" :cycle="false" :progress="true" :progress-color="primary"
                :hide-delimiters="true" :hide-delimiter-background="false">
                <v-carousel-item v-for="(question, index) in questions" :key="question.number">
                    <v-row class="d-flex justify-center">
                        <v-col cols="12" md="8">
                            <v-card class="mx-auto" max-width="70%">
                                <v-card-text class="bg-primary text-size-large">
                                    <v-icon left>mdi-head-lightbulb</v-icon>
                                    {{ question.number }}. {{ question.text }}
                                </v-card-text>
                                <v-divider class="mx-4 my-0"></v-divider>
                                <v-img :src="`/interests/${question.image}`" aspect-ratio="1.7"></v-img>
                                <v-chip-group class="d-flex justify-center flex-wrap" v-model="selections[index]"
                                    mandatory>
                                    <v-chip class="mx-2 d-flex align-center justify-center"
                                        v-for="(option, optIndex) in options" :key="optIndex" :value="option.value"
                                        color="primary" text-color="white"
                                        style="flex: 1 0 20%; font-size: 1.25rem; height: 40px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                                        @click="setSelection(index, option.value)">
                                        {{ option.label }}
                                    </v-chip>
                                </v-chip-group>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-carousel-item>
            </v-carousel>
        </v-form>
    </v-container>
    <v-footer color="primary" app></v-footer>
    <!-- Toast 通知メッセージ -->
    <v-snackbar v-model="snackbar" :timeout="3000" :color="snackbarColor">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
        </template>
    </v-snackbar>
</template>

<script setup>
import UnityNavigation from './common/UnityNavigation.vue';
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { fetchData, postJson } from '../utils/api';
import { INTERESTS_IMAGES } from '../utils/config';

const store = useStore();
const router = useRouter();
const selectedOffice = computed(() => store.getters.currentOffice);

let selectedUser = ref(null);
let users = ref([]);
const isUsersLoading = ref(false);
let questions = ref([]);
const options = ref([
    { label: 'している', value: 'doing' },
    { label: 'してみたい', value: 'want' },
    { label: '興味がある', value: 'interest' },
    { label: '無回答', value: 'nothing' }
]);
const isQuestionsLoading = ref(true);
let selections = ref([]);
let currentSlide = ref(0);

let questionData = ref([]);
let previousAnswer = ref([]);

const fetchCareUsers = async (currentOffice) => {
    if (currentOffice) {
        isUsersLoading.value = true;
        try {
            const data = await fetchData(`/est_users/${currentOffice}`);
            users.value = data.map((user) => ({
                text: user.c_user_name,
                value: user.c_user_id,
            }));
        } catch (error) {
            console.error(error);
        } finally {
            isUsersLoading.value = false;
        }
    } else {
        users.value = [];
    }
};

const fetchIdlQuestions = async () => {
    try {
        const data = await fetchData('/idl_questions');
        // 質問データを保持
        questionData.value = data;
        // DEBUG:
        console.log(questionData.value);

        questions.value = data.map((qst, index) => ({
            number: qst.q_num,
            text: qst.question,
            // 質問用の画像をセット
            image: INTERESTS_IMAGES[index]
        }));
        selections.value = Array(questions.value.length).fill(null);
    } catch (error) {
        console.error(error);
    } finally {
        isQuestionsLoading.value = false;
    }
};

const fetchPreviousIdlCheck = async () => {
    // ご利用者が選択されている場合のみ前回の興味関心チェック回答を取得する
    if (selectedUser.value && selectedUser.value.value !== void 0) {
        try {
            const data = await fetchData(`/idl_checks/${selectedUser.value.value}`);
            // 前回の回答データを保持
            previousAnswer.value = data;
            // DEBUG:
            console.log(previousAnswer.value);

            // 前回の興味関心チェック回答の有無を確認
            if (data.length > 0) {
                data.forEach((selection, index) => {
                    selections.value[index] = selection.answer;
                });
            } else {
                questions.value.forEach((q, index) => (selections.value[index] = "nothing"));
            }
        } catch (error) {
            if (error instanceof AxiosError && error.response?.status === 404) {
                // 404エラーの場合は前回の記録が存在しないのでデフォルト値をセット
                questions.value.forEach((q, index) => (selections.value[index] = "nothing"));
                console.log('該当するチェック記録が存在しません');
            } else {
                console.error(error);
            }
        }
    }
};

onMounted(() => {
    fetchCareUsers(selectedOffice.value);
    fetchIdlQuestions();
});

const clearSelectedUser = () => {
    selectedUser.value = null;
};

const setSelection = (questionIndex, value) => {
    // DEBUG:
    console.log(questionIndex, value);
    selections.value[questionIndex] = value;
    // 次のスライドに進む
    if (currentSlide.value < questions.value.length - 1) {
        currentSlide.value += 1;
    }
};

const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const day = ('0' + d.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
};

const snackbar = ref(false);
const snackbarColor = ref('success');
const snackbarText = ref('');

const showSnackbar = (color, text) => {
    snackbarColor.value = color;
    snackbarText.value = text;
    snackbar.value = true;
};

const submitForm = async () => {
    try {
        const converted = Object.keys(selections.value).map(key => selections.value[key]);
        const jsonData = {
            c_user_id: selectedUser.value.value,
            check_date: formatDate(new Date()),
            // 注）API の Scheme 定義に合わせて answers を使用
            answers: converted
        };
        const data = await postJson('/idl_check', jsonData);

        // 成功メッセージを表示
        showSnackbar('success', '興味関心の登録を完了しました。');

        // store にデータを保持
        store.dispatch('updateInterests', {
            questions: questionData.value,
            prevAnswers: previousAnswer.value,
            answers: selections.value,
        });

        await new Promise(resolve => setTimeout(resolve, 3500));
        // 正常終了の場合は結果ページへ遷移
        router.push('/interest-result');
    } catch (error) {
        console.error(error);
        // 成功メッセージを表示
        showSnackbar('error', '興味関心の登録に失敗しました。\nもう一度お試しください。');
    }
};
</script>

<style scoped>
.text-size-large {
    font-size: 2.0rem;
}
</style>